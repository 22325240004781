'use client'
import Cursor from '@/components/Atoms/Cursor'
import TitleJp from '@/components/Atoms/TitleJp'
import CareerItem from '@/components/Molecules/CareerItem'
import { TOP_CAREER } from '@/constants/data'
import { useCursor } from '@/hooks/useCursor'
import { useWindowSize } from '@/hooks/useWindowSize'

export default function Career() {
  const { width } = useWindowSize()
  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()
  return (
    <div>
      <TitleJp text="採用情報" subText="CAREERS" />
      <div className="md:flex justify-between mt-8 md:mt-10">
        {TOP_CAREER.map((item, index) => (
          <div
            className="md:w-[calc(50%_-_16px)] mb-4 md:mb-0 last:mb-0"
            key={`${item.text}-${index}`}
            onMouseEnter={() => {
              setMouseProperties({
                text: ' CLICK',
                color: '#ffffff',
                background: '#242A32',
              })
              setMouseDisplay(true)
            }}
            onMouseLeave={() => setMouseDisplay(false)}
          >
            <CareerItem
              text={item.text}
              textColor={item.textColor}
              imagePc={item.imagePc}
              imageSp={item.imageSp}
              href={item.href}
              target={index == 1 ? '_blank' : ''}
            />
          </div>
        ))}
      </div>
      {width > 768 && (
        <Cursor
          textMouse={mouseProperties.text}
          textColor={mouseProperties.color}
          background={mouseProperties.background}
          display={mouseDisplay}
          mixblendMode="mix-blend-hard-light"
        />
      )}
      {/* List link  */}
    </div>
  )
}
