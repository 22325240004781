import { useState } from 'react'

export const useCursor = () => {
  const [mouseProperties, setMouseProperties] = useState({
    text: '',
    color: '',
    background: '',
  })
  const [mouseDisplay, setMouseDisplay] = useState(false)

  return {
    mouseProperties,
    setMouseProperties,
    mouseDisplay,
    setMouseDisplay,
  }
}
