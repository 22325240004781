'use client'
import { useState } from 'react'
import Anwser from '../Atoms/Answer'
import Question from '../Atoms/Question'
import { LazyMotion, domAnimation } from 'framer-motion'

export default function Faq({
  data,
  size,
}: {
  data: { question: string; answer: string; id: number }[]
  size: 'sm' | 'lg'
}) {
  const [open, setOpen] = useState<number | null>(null)
  return (
    <div className="faq">
      <LazyMotion features={domAnimation}>
        {data.map((item, index) => (
          <div
            className="border-t border-darkgrey_100 last:border-b overflow-hidden"
            key={`${item}-${index}`}
          >
            <Question
              text={item.question}
              active={open}
              onClick={() => setOpen(open === item.id ? null : item.id)}
              id={item.id}
              size={size}
            />
            <Anwser id={item.id} text={item.answer} active={open} size={size} />
          </div>
        ))}
      </LazyMotion>
    </div>
  )
}
