import { useMousePosition } from '@/hooks/useMousePosition'
import { LazyMotion, cubicBezier, domAnimation, m } from 'framer-motion'
import React from 'react'

export default React.memo(function Cursor({
  textMouse,
  textColor,
  background,
  display,
  mixblendMode,
}: {
  textMouse: string
  textColor: string
  background: string
  display: boolean
  mixblendMode: string
}) {
  const { x, y } = useMousePosition()
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial={{ scale: 0 }}
        animate={{ x: x - 52, y: y - 52, scale: display ? 1 : 0 }}
        transition={{
          ease: cubicBezier(0, 0, 0.3, 1),
          duration: 0.2,
          scale: display && {
            type: 'spring',
            damping: 10,
            stiffness: 200,
            restDelta: 0.001,
          },
        }}
        className={`hidden md:block fixed top-0 left-0 m-auto tracking-wider pointer-events-none will-change-transform z-[999] ${mixblendMode}`}
      >
        <m.div
          className={`w-[104px] h-[104px] rounded-full flex items-center justify-center text-[14px] tracking-wide leading-none pointer-events-none bg-darkgrey_900 ${
            !display && 'text-transparent'
          } !font-avenirNextBold transition-colors duration-200 ease-linear`}
          style={{ color: textColor, background: background }}
        >
          {textMouse}
        </m.div>
      </m.div>
    </LazyMotion>
  )
})
