import Image from 'next/image'
import Link from 'next/link'

export default function JobCard({
  title,
  description,
  images,
  type,
  href,
  onEnter,
  onLeave,
}: {
  title: string
  description: string
  images: string
  type: string[]
  href: string
  onEnter?: () => void
  onLeave?: () => void
}) {
  return (
    <div className="relative group">
      <Link
        target="_blank"
        href={`${href}`}
        className="absolute left-0 top-0 w-full h-full block z-[1]"
      />
      <Link
        target="_blank"
        href={`${href}`}
        className="relative overflow-hidden rounded-[3px] block z-[2] transition duration-500 group-hover:shadow-[0_0_40px_-10px_rgba(92,111,131,0.4)]"
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        <Image
          alt=""
          src={images}
          width={700}
          height={300}
          className="w-full h-auto transition duration-500 group-hover:saturate-[120%] group-hover:brightness-95"
        />
      </Link>
      <p className={`w-full flex items-center justify-start mt-4 md:mt-6`}>
        <span
          className={`font-bold text-[14px] md:text-[18px] leading-[1.7]`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <span className="material-symbols-outlined text-blue_400 text-[14px] md:text-[16px] pl-2 mt-[1px] md:mt-[3px]">
          open_in_new
        </span>
      </p>
      <p
        className={`text-[11px] min-h-[62px] md:min-h-[66px] md:text-[12px] leading-[1.7] mt-1 md:mt-2 text-darkgrey_500`}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <hr className="border-dashed my-3 md:my-4" />
      <div className="flex flex-col md:flex-row pl-2">
        <span className="text-[10px] md:text-[12px] leading-[1.4] inline-block whitespace-nowrap text-darkgrey_500 font-medium md:mr-[16px] mb-2 md:mb-0">
          募集職種
        </span>
        <div className="flex-1 flex flex-wrap">
          {type.map((item, index) => (
            <span
              key={`type-${index}-${item}`}
              className="text-[10px] md:text-[12px] leading-[1.4] text-darkgrey_500 pr-[5px] mr-[5px] mb-1 border-r border-darkgrey_100 last:border-r-0 last:pr-0 last:mr-0"
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}
