import { AnimatePresence, m } from 'framer-motion'

export default function Anwser({
  id,
  text,
  active,
  size,
}: {
  id: number
  text: string
  active: number | null | number[] | null[]
  size: 'sm' | 'lg'
}) {
  const variants = {
    hidden: {
      height: 0,
      opacity: 0,
    },
    visible: {
      height: 'auto',
      opacity: 1,
    },
  }
  return (
    <AnimatePresence>
      {active === id && (
        <m.div
          variants={variants}
          initial={'hidden'}
          animate={'visible'}
          exit={'hidden'}
          transition={{ type: 'spring', duration: 0.3, bounce: 0 }}
        >
          <div
            className={`flex pl-11 md:pl-16 pr-14 md:pr-20 relative pb-4 ${size == 'sm' ? 'md:pb-5' : 'md:pb-8'}`}
          >
            <span
              className={`text-[16px] md:text-[20px] font-avenirNextBold leading-none absolute ${size == 'sm' ? 'top-1' : 'top-1 md:-top-3'} left-4 md:left-6`}
            >
              A.
            </span>
            <p
              className={`${size == 'lg' ? 'md:-mt-4' : ''} text-[12px] md:text-[14px] font-normal leading-[2] [&>small]:text-[10px] md:[&>small]:text-[12px] [&>small]:text-darkgrey_500 [&>picture>img]:rounded-[2px] md:[&>picture>img]:rounded-[6px] [&>picture>img]:w-full md:[&>picture>img]:mt-8 [&>picture>img]:mt-4`}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </m.div>
      )}
    </AnimatePresence>
  )
}
