'use client'
import Cursor from '@/components/Atoms/Cursor'
import TitleJp from '@/components/Atoms/TitleJp'
import JobCard from '@/components/Molecules/JobCard'
import More from '@/components/Molecules/More'
import { useCursor } from '@/hooks/useCursor'
import { useWindowSize } from '@/hooks/useWindowSize'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
export default function JobSession({
  data,
}: {
  data: {
    title: string
    images: string
    type: string[]
    description: string
    href: string
  }[]
}) {
  const pagination = {
    el: '.job-pagination',
    clickable: true,
  }
  const breakpoints = {
    769: {
      spaceBetween: 30,
    },
    320: {
      spaceBetween: 20,
    },
  }
  const { width } = useWindowSize()
  // Cursor properties
  const { mouseProperties, setMouseProperties, mouseDisplay, setMouseDisplay } =
    useCursor()
  return (
    <div className="relative">
      <TitleJp text="ピックアップ職種" subText="JOB CATEGORY" />
      {width > 768 ? (
        <>
          <div className="hidden md:flex flex-wrap md:[&>*]:w-[calc((100%_-_64px)_/_3)] md:[&>*]:mr-8 md:[&>*:nth-child(3n+3)]:mr-0 mt-8 md:mt-10">
            {data.map((item) => (
              <div
                className="mb-0 md:mb-16 group"
                key={`pc-${item.title}`}
                onMouseLeave={() => setMouseDisplay(false)}
              >
                <JobCard
                  title={item.title}
                  description={item.description}
                  type={item.type}
                  images={item.images}
                  href={item.href}
                  onEnter={() => {
                    setMouseProperties({
                      text: ' CLICK',
                      color: '#ffffff',
                      background: '#242A32',
                    })
                    setMouseDisplay(true)
                  }}
                  onLeave={() => setMouseDisplay(false)}
                />
              </div>
            ))}
          </div>

          <Cursor
            textMouse={mouseProperties.text}
            textColor={mouseProperties.color}
            background={mouseProperties.background}
            display={mouseDisplay}
            mixblendMode="mix-blend-hard-light"
          />
        </>
      ) : (
        <div className="md:hidden mt-8 md:mt-10 w-[calc(50%_+_94vw)] md:w-[calc(50%_+_60vw)]">
          <Swiper
            slidesPerView={'auto'}
            pagination={pagination}
            modules={[Pagination, Autoplay]}
            breakpoints={breakpoints}
            grabCursor={true}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            loop
          >
            {data.map((item) => (
              <SwiperSlide className="!w-1/2" key={`sp-${item.title}`}>
                <div className="mb-0 md:mb-16">
                  <JobCard
                    title={item.title}
                    description={item.description}
                    type={item.type}
                    images={item.images}
                    href={item.href}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <div className="flex items-center justify-between mt-8 pr-6">
        <div className="md:text-left md:absolute right-0 top-0 flex-1">
          <More href="/jobs" text="View more" type="text" />
        </div>
        <div className="text-right dot-pagination job-pagination md:hidden"></div>
      </div>
    </div>
  )
}
