'use client'
import { useEffect } from 'react'
import { Gradient } from 'whatamesh'

type Gradients = {
  id: string
  style: string
}

export default function Gradients({ id, style }: Gradients) {
  useEffect(() => {
    const gradient = new Gradient()
    gradient.initGradient(`#${id}`)
  }, [id])
  return <canvas id={id} className={style}></canvas>
}
