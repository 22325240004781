import { easeInOut, m } from 'framer-motion'

export default function Question({
  text,
  active,
  onClick,
  id,
  size,
}: {
  text: string
  active: number | null | number[] | null[]
  onClick: () => void
  id: number
  size: 'sm' | 'lg'
}) {
  const isOpen = active === id
  return (
    <div
      className={`relative flex py-4 ${size == 'sm' ? 'md:py-6' : 'md:py-8'} pl-11 md:pl-16 pr-12 md:pr-20 cursor-pointer bg-white group`}
      onClick={onClick}
    >
      <span
        className={`${
          isOpen && 'text-blue_400'
        } text-[16px] md:text-[20px] font-avenirNextBold leading-[1.2] absolute top-5 ${size == 'sm' ? 'md:top-7' : 'md:top-9'} left-4 md:left-6 duration-200 group-hover:text-blue_400`}
      >
        Q.
      </span>
      <p
        className={`${
          isOpen && 'text-blue_400'
        } text-[14px] md:text-[16px] font-normal leading-[2] duration-200 flex-1 group-hover:text-blue_400`}
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <m.div
        initial={{ rotate: 0 }}
        animate={{ rotate: isOpen ? -180 : 0 }}
        transition={{ duration: 0.2, ease: easeInOut }}
        className={`absolute right-4 md:right-6 top-5 ${size == 'sm' ? 'md:top-6' : 'md:top-9'} leading-none`}
      >
        <span className="material-symbols-outlined text-[24px] md:text-[28px] text-blue_400">
          expand_more
        </span>
      </m.div>
    </div>
  )
}
