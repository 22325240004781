export default function ArrowCircle({
  size,
  direction,
}: {
  size: 'lg' | 'sm'
  direction?: 'left' | 'right'
}) {
  return (
    <span className="inline-block">
      <span
        className={`${
          size == 'lg'
            ? 'w-[40px] md:w-[48px] h-[40px] md:h-[48px] '
            : 'w-[32px] md:w-[48px] h-[32px] md:h-[48px] '
        }border border-darkgrey_100 rounded-full leading-none flex items-center justify-center bg-white text-blue_400 group-hover:bg-blue_400 group-hover:text-white group-hover:border-blue_400 hover:bg-blue_400 hover:text-white transition-all duration-150`}
      >
        <span
          className={`material-symbols-outlined ${
            size == 'lg' ? 'text-[18px]' : 'text-[10px] md:text-[18px]'
          }`}
        >
          {direction === 'left' ? 'west' : 'east'}
        </span>
      </span>
    </span>
  )
}
