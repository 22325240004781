import Image from 'next/image'

export default function Picture({
  pcSrc,
  spSrc,
  screenSize,
  style,
  priority,
}: {
  pcSrc: string
  spSrc: string
  screenSize: string
  style: string
  priority?: boolean
}) {
  return (
    <picture>
      <source media={`(max-width:${screenSize}px )`} srcSet={spSrc} />
      {priority ? (
        <Image
          src={pcSrc}
          alt=""
          width={2000}
          height={700}
          className={style}
          priority
        />
      ) : (
        <Image src={pcSrc} alt="" width={2000} height={700} className={style} />
      )}
    </picture>
  )
}
