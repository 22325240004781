'use client'
import React from 'react'
import AnimatedText from './AnimatedText'
import { LazyMotion, domAnimation, m } from 'framer-motion'
const sub_sentence = {
  visible: {
    transition: {
      delayChildren: 1,
    },
  },
}
const sub = {
  hidden: {
    opacity: 0,
    clipPath: 'polygon(0 0, 0 0, 0 100%, 0% 100%)',
  },
  visible: {
    opacity: 1,
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    transition: {
      duration: 0.8,
      ease: [0.5, 1, 0.35, 1],
    },
  },
}
export default React.memo(function TitleJp({
  text,
  subText,
}: {
  text: string
  subText: string
}) {
  return (
    <div className="relative">
      <h3 className="text-[24px] md:text-[28px] leading-[1.7] font-bold tracking-[0.02em]">
        <AnimatedText txt_name={text} color="clip" />
      </h3>
      {subText != '' && (
        <LazyMotion features={domAnimation} strict>
          <m.p className="relative text-blue_400 pl-4 leading-none mt-2 flex items-center">
            <m.span
              variants={sub_sentence}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <m.span
                variants={sub}
                className="text-[16px] material-symbols-outlined text-blue_400 absolute left-0 top-1/2 -translate-y-1/2 -mt-[2px] md:-mt-[1px]"
              >
                stat_0
              </m.span>
            </m.span>
            <m.span
              className="uppercase text-[14px] md:text-[18px] font-avenirNextBold tracking-[0.08em] md:tracking-[0.04em]"
              variants={sub_sentence}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              {subText &&
                subText.split('').map((char, index) => {
                  return (
                    <span
                      key={index}
                      style={{
                        overflow: 'hidden',
                        display: 'inline-block',
                        verticalAlign: 'top',
                      }}
                    >
                      <m.span
                        key={char + '-' + index}
                        variants={sub}
                        style={{ display: 'inline-block' }}
                      >
                        {char === ' ' ? '\u00A0' : char}
                      </m.span>
                    </span>
                  )
                })}
            </m.span>
          </m.p>
        </LazyMotion>
      )}
    </div>
  )
})
