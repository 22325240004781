'use client'
import { DotLottieCommonPlayer, DotLottiePlayer } from '@dotlottie/react-player'
import '@dotlottie/react-player/dist/index.css'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
export default function LottieAnimation({ src }: { src: string }) {
  const lottieRef = useRef<DotLottieCommonPlayer>(null)
  const lottieParrentRef = useRef(null)
  const isInView = useInView(lottieParrentRef, { once: true })

  return (
    <div ref={lottieParrentRef}>
      {isInView && <DotLottiePlayer src={src} ref={lottieRef} autoplay />}
    </div>
  )
}
