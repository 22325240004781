import Link from 'next/link'
import Picture from '../Atoms/Picture'

export default function CareerItem({
  text,
  textColor,
  imagePc,
  imageSp,
  href,
}: {
  text: string
  textColor: string
  imagePc: string
  imageSp: string
  href: string
  target?: string
}) {
  return (
    <div className="relative block group">
      <Link
        href={`${href}`}
        className="absolute top-0 left-0 w-full h-full block z-50"
      />
      <div className="overflow-hidden rounded-[6px] md:rounded-[8px] transition duration-500 group-hover:shadow-[0_0_40px_-10px_rgba(92,111,131,0.4)]">
        <Picture
          screenSize="768"
          pcSrc={imagePc}
          spSrc={imageSp}
          style="w-full h-auto transition duration-500 group-hover:saturate-[120%] group-hover:brightness-95"
        />
      </div>
      <p
        className={`font-bold text-[22px] md:text-[32px] leading-none ${textColor} absolute left-8 md:left-10 top-1/2 -translate-y-1/2 whitespace-nowrap z-30`}
      >
        {text}
      </p>
    </div>
  )
}
